:root {
   
	
	--grey:				rgb(232,13,113);
	--spot: 			#242322;

	
	--animation-speed:	2s;
}

.preloader {
	display: block;

	position: relative;

	width: 16px;
	height: 6px;

	margin: 10px auto;

	background: var(--grey);

	animation-name: loader;
	animation-duration: var(--animation-speed);
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;

	&::before,
	&::after {
		content: '';

		position: absolute;

		top: 50%;

		width: 100%;
		height: 100%;

		box-sizing: border-box;

		background: var(--grey);

		animation-duration: var(--animation-speed);
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-fill-mode: forwards;
	}

	&::before {
		left: 0;

		translate: -100% -50%;

		border-right-color: transparent;

		animation-name: loader-left;
	}

	&::after {
		right: 0;

		translate: 100% -50%;

		border-left-color: transparent;

		animation-name: loader-right;
	}
}

@keyframes loader {
	0%, 10% {
		scale: 1;
	}
	15% {
		scale: 0.7 1.2;
	}
	40%, 60% {
		scale: 1;
	}
	65%{
		scale: 0.7 1.2;
	}
	100% {
		scale: 1;
	}
}

@keyframes loader-left {
	0% {
		translate: -400% -50%;
		scale: 1.3 0.6;
	}
	10%, 60% {
		translate: -100% -50%;
		scale: 1;
	}
	65%, 85% {
		translate: -300% -50%;
	}
	87.5% {
		translate: -310% -50%;
	}
	90% {
		translate: -290% -50%;
	}
	92.5% {
		translate: -310% -50%;
		scale: 1;
	}
	95% {
		translate: -290% -50%;
	}
	100% {
		translate: -400% -50%;
	}
}

@keyframes loader-right {
	0%, 10% {
		translate: 100% -50%;
		scale: 1;
	}
	15%, 35% {
		translate: 300% -50%;
	}
	15% {
		scale: 1.3 0.6;
	}
	20% {
		scale: 1;
	}
	37.5% {
		translate: 310% -50%;
	}
	40% {
		translate: 290% -50%;
	}
	42.5% {
		translate: 310% -50%;
	}
	45% {
		translate: 290% -50%;
		scale: 1;
	}
	50% {
		translate: 400% -50%;
		scale: 1.3 0.6;
	}
	60%, 100% {
		translate: 100% -50%;
		scale: 1;
	}
}






.main {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100vw;
	height: 100vh;

	padding: 20px;

	box-sizing: border-box;

	@media only screen and (min-width: 900px) {
		padding: 40px;
	}
}

.text {
	display: block;

	padding: 30px;
		
	color: var(--grey);
	text-transform: uppercase;
	font-size: 16px;
	letter-spacing: 4px;
	font-weight: 800;

	&:hover {
		background: var(--grey);
		color: var(--spot);

		.preloader,
		.preloader::before,
		.preloader::after {
			background: var(--spot);
		}
	}
}