:root { 
--white_A700_7f:#ffffff7f; 
--white_A700_d8:#ffffffd8; 
--blue_gray_100_01:#d1d5db; 
--gray_800_02:#4e4e4e; 
--blue_A200:#518ef8; 
--red_500:#f14336; 
--gray_800_01:#3c3c3c; 
--green_600:#28b446; 
--white_A700_99:#ffffff99; 
--black_900:#000000; 
--blue_gray_100_5e:#d9d9d95e; 
--pink_500:#e80d71; 
--blue_gray_900:#353535; 
--deep_orange_400:#f2684a; 
--pink_500_99:#ff0074e3; 
--deep_orange_A400:#ff3717; 
--white_A700_4c:#ffffff4c; 
--gray_600:#6c6c6c; 
--gray_900_02:#2a2a2a; 
--gray_900_03:#23211f; 
--gray_700:#666666; 
--pink_50:#ffe0ee; 
--gray_900_04:#282828; 
--gray_400:#b6b5b5; 
--gray_600_01:#858585; 
--blue_gray_100:#cfd0d1; 
--gray_900_05:#181614; 
--blue_gray_400:#868686; 
--white_A700_0c:#ffffff0c; 
--blue_gray_300:#9ca3af; 
--gray_800:#3f3838; 
--amber_600:#fbbb00; 
--gray_900:#121212; 
--gray_900_01:#121214; 
--white_A700_4f:#ffffff4f; 
--gray_200:#edeeef; 
--white_A700_21:#ffffff21; 
--white_A700_66:#ffffff66; 
--indigo_600_01:#345e90; 
--indigo_A700:#0000ff; 
--black_900_ea:#000000ea; 
--blue_400:#4598d3; 
--blue_gray_400_02:#8a8886; 
--white_A700_28:#ffffff28; 
--blue_gray_400_01:#718d8e; 
--white_A700:#ffffff; 
--white_A700_7a:#ffffff7a; 
--indigo_600:#2f5b9f; 
}