:root {
  background-color: #121214 !important;
  scroll-behavior: smooth;
}

.text-lg {
  font-size: 16px !important;
}

.text-pink-500_99 {
  color: #ff0074e3 !important;
}

.expanded {
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(232, 13, 95) !important;
  -webkit-box-shadow: 0 0 0 1000px black inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.colorChangeHover :hover {
  color: rgb(232, 13, 95) !important;
  transform: scale(1.1);
}
.colorChangeHoverWhite :hover {
  transform: scale(1.1);
}

 /* img {
  display: none !important;
}  */

p {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 28px !important; 
  color: rgb(209, 213, 219) !important;
}

h3{
Font-size: 20px !important;
Font-weight: 600 !important;
Line-height: 32px !important;
color: rgb(255, 255, 255) !important;
}

h2{
    Font-size: 24px !important;
    Font-weight: 700 !important;
    Line-height: 32px !important;
    color: rgb(255, 255, 255) !important;
    }

h2 strong{
Font-size: 24px !important;
 Font-weight: 800 !important;
 Line-height: 32px !important;
 color: rgb(255, 255, 255) !important;
    }

  
     h1 {
   
      text-align: left;
  
      text-transform: capitalize;
      Font-size: 30px !important;
      Font-weight: 700 !important;
      Line-height: 32px !important;
      color: rgb(255, 255, 255) !important;
  }
  