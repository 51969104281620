.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.extraDivCity{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.MuiSvgIcon-colorPrimary {
    color: rgb(18,18,20);
}

.MuiSvgIcon-colorSecondary {
    color: rgba(240, 171, 21, 1);
}

.MuiSvgIcon-colorAction {
    color: #fff;
}

.MuiSvgIcon-colorError {
    color: #f44336;
}

.MuiSvgIcon-colorDisabled {
    color: rgba(255, 255, 255, 0.3);
}

.MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
}

.MuiSvgIcon-fontSizeSmall {
    font-size: 1.25rem;
}

.MuiSvgIcon-fontSizeLarge {
    font-size: 2.1875rem;
}

.MuiPaper-root {
    color: rgb(18,18,20);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(18,18,20);
}

.MuiPaper-rounded {
    border-radius: 6px;
}

.MuiPaper-outlined {
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiPaper-elevation0 {
    box-shadow: none;
}

.MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation3 {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation5 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation6 {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation7 {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation8 {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation9 {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation10 {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation11 {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation12 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation13 {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation14 {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation15 {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation16 {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation17 {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation18 {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation19 {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation20 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation21 {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation22 {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation23 {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.MuiPaper-elevation24 {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiButtonBase-root::-moz-focus-inner {
    border-style: none;
}

.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointer-events: none;
}

@media print {
    .MuiButtonBase-root {
        color-adjust: exact;
    }
}

.MuiIconButton-root {
    flex: 0 0 auto;
    color: #fff;
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.MuiIconButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
}

@media (hover: none) {
    .MuiIconButton-root:hover {
        background-color: transparent;
    }
}

.MuiIconButton-edgeStart {
    margin-left: -12px;
}

.MuiIconButton-sizeSmall.MuiIconButton-edgeStart {
    margin-left: -3px;
}

.MuiIconButton-edgeEnd {
    margin-right: -12px;
}

.MuiIconButton-sizeSmall.MuiIconButton-edgeEnd {
    margin-right: -3px;
}

.MuiIconButton-colorInherit {
    color: inherit;
}

.MuiIconButton-colorPrimary {
    color: rgb(18,18,20);
}

.MuiIconButton-colorPrimary:hover {
    background-color: rgba(28, 28, 28, 0.08);
}

@media (hover: none) {
    .MuiIconButton-colorPrimary:hover {
        background-color: transparent;
    }
}

.MuiIconButton-colorSecondary {
    color: rgba(240, 171, 21, 1);
}

.MuiIconButton-colorSecondary:hover {
    background-color: rgba(240, 171, 21, 0.08);
}

@media (hover: none) {
    .MuiIconButton-colorSecondary:hover {
        background-color: transparent;
    }
}

.MuiIconButton-sizeSmall {
    padding: 3px;
    font-size: 1.125rem;
}

.MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
}

.MuiAppBar-root {
    width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
}

.MuiAppBar-positionFixed {
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
}

@media print {
    .MuiAppBar-positionFixed {
        position: absolute;
    }
}

.MuiAppBar-positionAbsolute {
    top: 0;
    left: auto;
    right: 0;
    position: absolute;
}

.MuiAppBar-positionSticky {
    top: 0;
    left: auto;
    right: 0;
    position: sticky;
}

.MuiAppBar-positionStatic {
    position: static;
}

.MuiAppBar-positionRelative {
    position: relative;
}

.MuiAppBar-colorDefault {
    color: #fff;
    background-color: #212121;
}

.MuiAppBar-colorPrimary {
    color: #fff;
    background-color: rgb(18,18,20);
}

.MuiAppBar-colorSecondary {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(240, 171, 21, 1);
}

.MuiAppBar-colorInherit {
    color: inherit;
}

.MuiAppBar-colorTransparent {
    color: inherit;
    background-color: transparent;
}

.jss19 {
    padding: 32px;
}

.jss49 {
    height: 400px;
    padding-left: 76.8px;
    padding-right: 76.8px;
}

.jss50 {}

.jss62 {
    width: 100%;
    height: 80px;
}

.jss414 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss415 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss416 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss417 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss418 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss419 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss420 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss421 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss422 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss423 {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.jss424 {
    display: flex;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: column;
    justify-content: center;
}

.jss426 {
    align-self: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.jss430 {
    width: 100%;
    padding: 16px;
}

.jss431 {
    width: 100%;
    height: 100%;
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 48px;
}

.jss432 {
    display: flex;
}

.jss433 {
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: space-between;
}

.jss434 {}

.jss435 {
    font-weight: 500;
}

.MuiTypography-root {
    margin: 0;
}

.MuiTypography-body2 {
    font-size: 0.875rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
}

.MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
}

.MuiTypography-caption {
    font-size: 0.8125rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
}

.MuiTypography-button {
    font-size: 0.875rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
}

.MuiTypography-h1 {
    font-size: 4rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 200;
    line-height: 1.167;
}

.MuiTypography-h2 {
    font-size: 2.875rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 200;
    line-height: 1.2;
}

.MuiTypography-h3 {
    font-size: 2.25rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.167;
}

.MuiTypography-h4 {
    font-size: 1.4rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.235;
}

.MuiTypography-h5 {
    font-size: 1.5rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
}

.MuiTypography-h6 {
    font-size: 1.25rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
}

.MuiTypography-subtitle1 {
    font-size: 1.1875rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
}

.MuiTypography-subtitle2 {
    font-size: 0.875rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.57;
}

.MuiTypography-overline {
    font-size: 0.75rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 2.66;
    text-transform: uppercase;
}

.MuiTypography-srOnly {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
}

.MuiTypography-alignLeft {
    text-align: left;
}

.MuiTypography-alignCenter {
    text-align: center;
}

.MuiTypography-alignRight {
    text-align: right;
}

.MuiTypography-alignJustify {
    text-align: justify;
}

.MuiTypography-noWrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiTypography-gutterBottom {
    margin-bottom: 0.35em;
}

.MuiTypography-paragraph {
    margin-bottom: 16px;
}

.MuiTypography-colorInherit {
    color: inherit;
}

.MuiTypography-colorPrimary {
    color: rgb(18,18,20);
}

.MuiTypography-colorSecondary {
    color: #E80D71;
}

.MuiTypography-colorTextPrimary {
    color: rgb(18,18,20);
}

.MuiTypography-colorTextSecondary {
    color: rgba(81, 81, 81, 1);
}

.MuiTypography-colorError {
    color: #f44336;
}

.MuiTypography-displayInline {
    display: inline;
}

.MuiTypography-displayBlock {
    display: block;
}

.MuiButton-root {
    color: rgb(18,18,20);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 6px;
    text-transform: uppercase;
}

.MuiButton-root:hover {
    text-decoration: none;
    background-color: rgba(28, 28, 28, 0.08);
}

.MuiButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.3);
}

@media (hover: none) {
    .MuiButton-root:hover {
        background-color: transparent;
    }
}

.MuiButton-root:hover.Mui-disabled {
    background-color: transparent;
}

.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.MuiButton-text {
    padding: 6px 8px;
}

.MuiButton-textPrimary {
    color: rgb(18,18,20);
}

.MuiButton-textPrimary:hover {
    background-color: rgba(28, 28, 28, 0.08);
}

@media (hover: none) {
    .MuiButton-textPrimary:hover {
        background-color: transparent;
    }
}

.MuiButton-textSecondary {
    color: rgba(240, 171, 21, 1);
}

.MuiButton-textSecondary:hover {
    background-color: rgba(240, 171, 21, 0.08);
}

@media (hover: none) {
    .MuiButton-textSecondary:hover {
        background-color: transparent;
    }
}

.MuiButton-outlined {
    border: 1px solid rgba(255, 255, 255, 0.23);
    padding: 5px 15px;
}

.MuiButton-outlined.Mui-disabled {
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiButton-outlinedPrimary {
    color: rgb(18,18,20);
    border: 1px solid rgba(28, 28, 28, 0.5);
}

.MuiButton-outlinedPrimary:hover {
    border: 1px solid rgb(18,18,20);
    background-color: rgba(28, 28, 28, 0.08);
}

@media (hover: none) {
    .MuiButton-outlinedPrimary:hover {
        background-color: transparent;
    }
}

.MuiButton-outlinedSecondary {
    color: rgba(240, 171, 21, 1);
    border: 1px solid rgba(240, 171, 21, 0.5);
}

.MuiButton-outlinedSecondary:hover {
    border: 1px solid rgba(240, 171, 21, 1);
    background-color: rgba(240, 171, 21, 0.08);
}

.MuiButton-outlinedSecondary.Mui-disabled {
    border: 1px solid rgba(255, 255, 255, 0.3);
}

@media (hover: none) {
    .MuiButton-outlinedSecondary:hover {
        background-color: transparent;
    }
}

.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    background-color: #f2f2f2;
}

.MuiButton-contained:hover {
    box-shadow: none;
    background-color: #e2e2e2;
}

.MuiButton-contained.Mui-focusVisible {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.MuiButton-contained:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.MuiButton-contained.Mui-disabled {
    color: rgba(255, 255, 255, 0.3);
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.12);
}

@media (hover: none) {
    .MuiButton-contained:hover {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        background-color: #e0e0e0;
    }
}

.MuiButton-contained:hover.Mui-disabled {
    background-color: rgba(255, 255, 255, 0.12);
}

.MuiButton-containedPrimary {
    color: #fff;
    background-color: rgb(18,18,20);
}

.MuiButton-containedPrimary:hover {
    background-color: rgba(19, 19, 19, 1);
}

@media (hover: none) {
    .MuiButton-containedPrimary:hover {
        background-color: rgb(18,18,20);
    }
}

.MuiButton-containedSecondary {
    color: rgba(255, 255, 255, 1);
    padding: 12px 32px;
    font-weight: 500;
    background-color: #E80D71;
}

.MuiButton-containedSecondary:hover {
    background-color: rgb(254, 40, 158);
}

@media (hover: none) {
    .MuiButton-containedSecondary:hover {
        background-color: rgba(240, 171, 21, 1);
    }
}

.MuiButton-disableElevation {
    box-shadow: none;
}

.MuiButton-disableElevation:hover {
    box-shadow: none;
}

.MuiButton-disableElevation.Mui-focusVisible {
    box-shadow: none;
}

.MuiButton-disableElevation:active {
    box-shadow: none;
}

.MuiButton-disableElevation.Mui-disabled {
    box-shadow: none;
}

.MuiButton-colorInherit {
    color: inherit;
    border-color: currentColor;
}

.MuiButton-textSizeSmall {
    padding: 4px 5px;
    font-size: 0.8125rem;
}

.MuiButton-textSizeLarge {
    padding: 8px 11px;
    font-size: 0.9375rem;
}

.MuiButton-outlinedSizeSmall {
    padding: 3px 9px;
    font-size: 0.8125rem;
}

.MuiButton-outlinedSizeLarge {
    padding: 7px 21px;
    font-size: 0.9375rem;
}

.MuiButton-containedSizeSmall {
    padding: 4px 10px;
    font-size: 0.8125rem;
}

.MuiButton-containedSizeLarge {
    padding: 8px 22px;
    font-size: 0.9375rem;
    padding-top: 16px;
    padding-bottom: 16px;
}

.MuiButton-fullWidth {
    width: 100%;
}

.MuiButton-startIcon {
    display: inherit;
    margin-left: -4px;
    margin-right: 8px;
}

.MuiButton-startIcon.MuiButton-iconSizeSmall {
    margin-left: -2px;
}

.MuiButton-endIcon {
    display: inherit;
    margin-left: 8px;
    margin-right: -4px;
}

.MuiButton-endIcon.MuiButton-iconSizeSmall {
    margin-right: -2px;
}

.MuiButton-iconSizeSmall>*:first-child {
    font-size: 18px;
}

.MuiButton-iconSizeMedium>*:first-child {
    font-size: 20px;
}

.MuiButton-iconSizeLarge>*:first-child {
    font-size: 22px;
}

.MuiCard-root {
    overflow: hidden;
}

.MuiCardActions-root {
    display: flex;
    padding: 8px;
    align-items: center;
}

.MuiCardActions-spacing> :not(:first-child) {
    margin-left: 8px;
}

.MuiCardContent-root {
    padding: 16px;
}

.MuiCardContent-root:last-child {
    padding-bottom: 24px;
}

.MuiChip-root {
    color: #fff;
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.7rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 500;
    white-space: nowrap;
    border-radius: 4px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: rgba(231, 229, 229, 0.5);
}

.MuiChip-root.Mui-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.MuiChip-root .MuiChip-avatar {
    color: #e0e0e0;
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
    margin-left: 5px;
    margin-right: -6px;
}

.MuiChip-root .MuiChip-avatarColorPrimary {
    color: #fff;
    background-color: rgba(19, 19, 19, 1);
}

.MuiChip-root .MuiChip-avatarColorSecondary {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(227, 162, 19, 1);
}

.MuiChip-root .MuiChip-avatarSmall {
    width: 18px;
    height: 18px;
    font-size: 0.625rem;
    margin-left: 4px;
    margin-right: -4px;
}

.MuiChip-sizeSmall {
    height: 24px;
}

.MuiChip-colorPrimary {
    color: #fff;
    background-color: rgb(18,18,20);
}

.MuiChip-colorSecondary {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(240, 171, 21, 1);
}

.MuiChip-clickable {
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiChip-clickable:hover,
.MuiChip-clickable:focus {
    background-color: rgb(109, 109, 109);
}

.MuiChip-clickable:active {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiChip-clickableColorPrimary:hover,
.MuiChip-clickableColorPrimary:focus {
    background-color: rgba(46, 46, 46, 1);
}

.MuiChip-clickableColorSecondary:hover,
.MuiChip-clickableColorSecondary:focus {
    background-color: rgba(241, 177, 39, 1);
}

.MuiChip-deletable:focus {
    background-color: rgb(109, 109, 109);
}

.MuiChip-deletableColorPrimary:focus {
    background-color: rgba(73, 73, 73, 1);
}

.MuiChip-deletableColorSecondary:focus {
    background-color: rgba(243, 187, 67, 1);
}

.MuiChip-outlined {
    border: 1px solid rgba(255, 255, 255, 0.23);
    background-color: transparent;
}

.MuiChip-clickable.MuiChip-outlined:hover,
.MuiChip-clickable.MuiChip-outlined:focus,
.MuiChip-deletable.MuiChip-outlined:focus {
    background-color: rgba(28, 28, 28, 0.08);
}

.MuiChip-outlined .MuiChip-avatar {
    margin-left: 4px;
}

.MuiChip-outlined .MuiChip-avatarSmall {
    margin-left: 2px;
}

.MuiChip-outlined .MuiChip-icon {
    margin-left: 4px;
}

.MuiChip-outlined .MuiChip-iconSmall {
    margin-left: 2px;
}

.MuiChip-outlined .MuiChip-deleteIcon {
    margin-right: 5px;
}

.MuiChip-outlined .MuiChip-deleteIconSmall {
    margin-right: 3px;
}

.MuiChip-outlinedPrimary {
    color: rgb(18,18,20);
    border: 1px solid rgb(18,18,20);
}

.MuiChip-clickable.MuiChip-outlinedPrimary:hover,
.MuiChip-clickable.MuiChip-outlinedPrimary:focus,
.MuiChip-deletable.MuiChip-outlinedPrimary:focus {
    background-color: rgba(28, 28, 28, 0.08);
}

.MuiChip-outlinedSecondary {
    color: rgba(240, 171, 21, 1);
    border: 1px solid rgba(240, 171, 21, 1);
}

.MuiChip-clickable.MuiChip-outlinedSecondary:hover,
.MuiChip-clickable.MuiChip-outlinedSecondary:focus,
.MuiChip-deletable.MuiChip-outlinedSecondary:focus {
    background-color: rgba(240, 171, 21, 0.08);
}

.MuiChip-icon {
    color: #e0e0e0;
    margin-left: 5px;
    margin-right: -6px;
}

.MuiChip-iconSmall {
    width: 14px;
    height: 18px;
    margin-left: 4px;
    margin-right: -4px;
}

.MuiChip-iconColorPrimary {
    color: inherit;
}

.MuiChip-iconColorSecondary {
    color: inherit;
}

.MuiChip-label {
    overflow: hidden;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: ellipsis;
}

.MuiChip-labelSmall {
    padding-left: 8px;
    padding-right: 8px;
}

.MuiChip-deleteIcon {
    color: rgba(28, 28, 28, 0.26);
    width: 22px;
    cursor: pointer;
    height: 22px;
    margin: 0 5px 0 -6px;
    -webkit-tap-highlight-color: transparent;
}

.MuiChip-deleteIcon:hover {
    color: rgba(28, 28, 28, 0.4);
}

.MuiChip-deleteIconSmall {
    width: 16px;
    height: 16px;
    margin-left: -4px;
    margin-right: 4px;
}

.MuiChip-deleteIconColorPrimary {
    color: rgba(255, 255, 255, 0.7);
}

.MuiChip-deleteIconColorPrimary:hover,
.MuiChip-deleteIconColorPrimary:active {
    color: #fff;
}

.MuiChip-deleteIconColorSecondary {
    color: rgba(0, 0, 0, 0.7);
}

.MuiChip-deleteIconColorSecondary:hover,
.MuiChip-deleteIconColorSecondary:active {
    color: rgba(0, 0, 0, 0.87);
}

.MuiChip-deleteIconOutlinedColorPrimary {
    color: rgba(28, 28, 28, 0.7);
}

.MuiChip-deleteIconOutlinedColorPrimary:hover,
.MuiChip-deleteIconOutlinedColorPrimary:active {
    color: rgb(18,18,20);
}

.MuiChip-deleteIconOutlinedColorSecondary {
    color: rgba(240, 171, 21, 0.7);
}

.MuiChip-deleteIconOutlinedColorSecondary:hover,
.MuiChip-deleteIconOutlinedColorSecondary:active {
    color: rgba(240, 171, 21, 1);
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

strong,
b {
    font-weight: 700;
}

body {
    color: rgb(18,18,20);
    margin: 0;
    font-size: 0.875rem;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    
}

@media print {
    body {
        background-color: #fff;
    }
}



h4 {
    font-size: 1.5rem;
    font-weight: 300;
}

p {
    font-size: 1rem;
    font-weight: 400;
}

a {
    color: #ffffff;
    text-decoration: none;
}

.MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.12);
}

.MuiDivider-absolute {
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
}

.MuiDivider-inset {
    margin-left: 72px;
}

.MuiDivider-light {
    background-color: rgba(255, 255, 255, 0.18);
}

.MuiDivider-middle {
    margin-left: 16px;
    margin-right: 16px;
}

.MuiDivider-vertical {
    width: 1px;
    height: 100%;
}

.MuiDivider-flexItem {
    height: auto;
    align-self: stretch;
}

.MuiDrawer-docked {
    flex: 0 0 auto;
}

.MuiDrawer-paper {
    top: 0;
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    outline: 0;
    z-index: 1200;
    position: fixed;
    overflow-y: auto;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
}

.MuiDrawer-paperAnchorLeft {
    left: 0;
    right: auto;
}

.MuiDrawer-paperAnchorRight {
    left: auto;
    right: 0;
}

.MuiDrawer-paperAnchorTop {
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    height: auto;
    max-height: 100%;
}

.MuiDrawer-paperAnchorBottom {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    max-height: 100%;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiDrawer-paperAnchorDockedTop {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiDrawer-paperAnchorDockedRight {
    border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiDrawer-paperAnchorDockedBottom {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
}

.MuiGrid-zeroMinWidth {
    min-width: 0;
}

.MuiGrid-direction-xs-column {
    flex-direction: column;
}

.MuiGrid-direction-xs-column-reverse {
    flex-direction: column-reverse;
}

.MuiGrid-direction-xs-row-reverse {
    flex-direction: row-reverse;
}

.MuiGrid-wrap-xs-nowrap {
    flex-wrap: nowrap;
}

.MuiGrid-wrap-xs-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.MuiGrid-align-items-xs-center {
    align-items: center;
}

.MuiGrid-align-items-xs-flex-start {
    align-items: flex-start;
}

.MuiGrid-align-items-xs-flex-end {
    align-items: flex-end;
}

.MuiGrid-align-items-xs-baseline {
    align-items: baseline;
}

.MuiGrid-align-content-xs-center {
    align-content: center;
}

.MuiGrid-align-content-xs-flex-start {
    align-content: flex-start;
}

.MuiGrid-align-content-xs-flex-end {
    align-content: flex-end;
}

.MuiGrid-align-content-xs-space-between {
    align-content: space-between;
}

.MuiGrid-align-content-xs-space-around {
    align-content: space-around;
}

.MuiGrid-justify-xs-center {
    justify-content: center;
}

.MuiGrid-justify-xs-flex-end {
    justify-content: flex-end;
}

.MuiGrid-justify-xs-space-between {
    justify-content: space-between;
}

.MuiGrid-justify-xs-space-around {
    justify-content: space-around;
}

.MuiGrid-justify-xs-space-evenly {
    justify-content: space-evenly;
}

.MuiGrid-spacing-xs-1 {
    width: calc(100% + 8px);
    margin: -4px;
}

.MuiGrid-spacing-xs-1>.MuiGrid-item {
    padding: 4px;
}

.MuiGrid-spacing-xs-2 {
    width: calc(100% + 16px);
    margin: -8px;
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
    padding: 8px;
}

.MuiGrid-spacing-xs-3 {
    width: calc(100% + 24px);
    margin: -12px;
}

.MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 12px;
}

.MuiGrid-spacing-xs-4 {
    width: calc(100% + 32px);
    margin: -16px;
}

.MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding: 16px;
}

.MuiGrid-spacing-xs-5 {
    width: calc(100% + 40px);
    margin: -20px;
}

.MuiGrid-spacing-xs-5>.MuiGrid-item {
    padding: 20px;
}

.MuiGrid-spacing-xs-6 {
    width: calc(100% + 48px);
    margin: -24px;
}

.MuiGrid-spacing-xs-6>.MuiGrid-item {
    padding: 24px;
}

.MuiGrid-spacing-xs-7 {
    width: calc(100% + 56px);
    margin: -28px;
}

.MuiGrid-spacing-xs-7>.MuiGrid-item {
    padding: 28px;
}

.MuiGrid-spacing-xs-8 {
    width: calc(100% + 64px);
    margin: -32px;
}

.MuiGrid-spacing-xs-8>.MuiGrid-item {
    padding: 32px;
}

.MuiGrid-spacing-xs-9 {
    width: calc(100% + 72px);
    margin: -36px;
}

.MuiGrid-spacing-xs-9>.MuiGrid-item {
    padding: 36px;
}

.MuiGrid-spacing-xs-10 {
    width: calc(100% + 80px);
    margin: -40px;
}

.MuiGrid-spacing-xs-10>.MuiGrid-item {
    padding: 40px;
}

.MuiGrid-grid-xs-auto {
    flex-grow: 0;
    max-width: none;
    flex-basis: auto;
}

.MuiGrid-grid-xs-true {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 0;
}

.MuiGrid-grid-xs-1 {
    flex-grow: 0;
    max-width: 8.333333%;
    flex-basis: 8.333333%;
}

.MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
}

.MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
}

.MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 33.333333%;
    flex-basis: 33.333333%;
}

.MuiGrid-grid-xs-5 {
    flex-grow: 0;
    max-width: 41.666667%;
    flex-basis: 41.666667%;
}

.MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 50%;
}

.MuiGrid-grid-xs-7 {
    flex-grow: 0;
    max-width: 58.333333%;
    flex-basis: 58.333333%;
}

.MuiGrid-grid-xs-8 {
    flex-grow: 0;
    max-width: 66.666667%;
    flex-basis: 66.666667%;
}

.MuiGrid-grid-xs-9 {
    flex-grow: 0;
    max-width: 75%;
    flex-basis: 75%;
}

.MuiGrid-grid-xs-10 {
    flex-grow: 0;
    max-width: 83.333333%;
    flex-basis: 83.333333%;
}

.MuiGrid-grid-xs-11 {
    flex-grow: 0;
    max-width: 91.666667%;
    flex-basis: 91.666667%;
}

.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}

@media (min-width:600px) {
    .MuiGrid-grid-sm-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-sm-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-sm-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-sm-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-sm-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-sm-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-sm-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-sm-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-sm-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-sm-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-sm-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-sm-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-sm-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-sm-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width:960px) {
    .MuiGrid-grid-md-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-md-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-md-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-md-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-md-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-md-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-md-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-md-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-md-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-md-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-md-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-md-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-md-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width:1280px) {
    .MuiGrid-grid-lg-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-lg-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-lg-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-lg-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-lg-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-lg-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-lg-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-lg-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-lg-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-lg-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-lg-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-lg-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-lg-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-lg-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

@media (min-width:1920px) {
    .MuiGrid-grid-xl-auto {
        flex-grow: 0;
        max-width: none;
        flex-basis: auto;
    }

    .MuiGrid-grid-xl-true {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
    }

    .MuiGrid-grid-xl-1 {
        flex-grow: 0;
        max-width: 8.333333%;
        flex-basis: 8.333333%;
    }

    .MuiGrid-grid-xl-2 {
        flex-grow: 0;
        max-width: 16.666667%;
        flex-basis: 16.666667%;
    }

    .MuiGrid-grid-xl-3 {
        flex-grow: 0;
        max-width: 25%;
        flex-basis: 25%;
    }

    .MuiGrid-grid-xl-4 {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .MuiGrid-grid-xl-5 {
        flex-grow: 0;
        max-width: 41.666667%;
        flex-basis: 41.666667%;
    }

    .MuiGrid-grid-xl-6 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }

    .MuiGrid-grid-xl-7 {
        flex-grow: 0;
        max-width: 58.333333%;
        flex-basis: 58.333333%;
    }

    .MuiGrid-grid-xl-8 {
        flex-grow: 0;
        max-width: 66.666667%;
        flex-basis: 66.666667%;
    }

    .MuiGrid-grid-xl-9 {
        flex-grow: 0;
        max-width: 75%;
        flex-basis: 75%;
    }

    .MuiGrid-grid-xl-10 {
        flex-grow: 0;
        max-width: 83.333333%;
        flex-basis: 83.333333%;
    }

    .MuiGrid-grid-xl-11 {
        flex-grow: 0;
        max-width: 91.666667%;
        flex-basis: 91.666667%;
    }

    .MuiGrid-grid-xl-12 {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
    }
}

.MuiGridList-root {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.MuiGridListTile-root {
    box-sizing: border-box;
    flex-shrink: 0;
}

.MuiGridListTile-tile {
    height: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}

.MuiGridListTile-imgFullHeight {
    left: 50%;
    height: 100%;
    position: relative;
    transform: translateX(-50%);
}

.MuiGridListTile-imgFullWidth {
    top: 50%;
    width: 100%;
    position: relative;
    transform: translateY(-50%);
}

.MuiLink-underlineNone {
    text-decoration: none;
}

.MuiLink-underlineHover {
    text-decoration: none;
}

.MuiLink-underlineHover:hover {
    text-decoration: underline;
}

.MuiLink-underlineAlways {
    text-decoration: underline;
}

.MuiLink-button {
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: 0;
    padding: 0;
    position: relative;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiLink-button::-moz-focus-inner {
    border-style: none;
}

.MuiLink-button.Mui-focusVisible {
    outline: auto;
}

.MuiList-root {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
}

.MuiList-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}

.MuiList-subheader {
    padding-top: 0;
}

.MuiListItem-root {
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
}

.MuiListItem-root.Mui-focusVisible {
    background-color: rgba(255, 255, 255, 0.16);
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    background-color: rgba(255, 255, 255, 0.16);
}

.MuiListItem-root.Mui-disabled {
    opacity: 0.5;
}

.MuiListItem-container {
    position: relative;
}

.MuiListItem-dense {
    padding-top: 4px;
    padding-bottom: 4px;
}

.MuiListItem-alignItemsFlexStart {
    align-items: flex-start;
}

.MuiListItem-divider {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-clip: padding-box;
}

.MuiListItem-gutters {
    padding-left: 16px;
    padding-right: 16px;
}

.MuiListItem-button {
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiListItem-button:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.08);
}

@media (hover: none) {
    .MuiListItem-button:hover {
        background-color: transparent;
    }
}

.MuiListItem-secondaryAction {
    padding-right: 48px;
}

.MuiListItemText-root {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
}

.MuiListItemText-multiline {
    margin-top: 6px;
    margin-bottom: 6px;
}

.MuiListItemText-inset {
    padding-left: 56px;
}

.MuiPopover-paper {
    outline: 0;
    position: absolute;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 16px;
    overflow-x: hidden;
    overflow-y: auto;
}

.MuiMenu-paper {
    max-height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;
}

.MuiMenu-list {
    outline: 0;
}

.MuiSnackbar-root {
    left: 8px;
    right: 8px;
    display: flex;
    z-index: 1400;
    position: fixed;
    align-items: center;
    justify-content: center;
}

.MuiSnackbar-anchorOriginTopCenter {
    top: 8px;
}

@media (min-width:600px) {
    .MuiSnackbar-anchorOriginTopCenter {
        top: 24px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
}

.MuiSnackbar-anchorOriginBottomCenter {
    bottom: 8px;
}

@media (min-width:600px) {
    .MuiSnackbar-anchorOriginBottomCenter {
        left: 50%;
        right: auto;
        bottom: 24px;
        transform: translateX(-50%);
    }
}

.MuiSnackbar-anchorOriginTopRight {
    top: 8px;
    justify-content: flex-end;
}

@media (min-width:600px) {
    .MuiSnackbar-anchorOriginTopRight {
        top: 24px;
        left: auto;
        right: 24px;
    }
}

.MuiSnackbar-anchorOriginBottomRight {
    bottom: 8px;
    justify-content: flex-end;
}

@media (min-width:600px) {
    .MuiSnackbar-anchorOriginBottomRight {
        left: auto;
        right: 24px;
        bottom: 24px;
    }
}

.MuiSnackbar-anchorOriginTopLeft {
    top: 8px;
    justify-content: flex-start;
}

@media (min-width:600px) {
    .MuiSnackbar-anchorOriginTopLeft {
        top: 24px;
        left: 24px;
        right: auto;
    }
}

.MuiSnackbar-anchorOriginBottomLeft {
    bottom: 8px;
    justify-content: flex-start;
}

@media (min-width:600px) {
    .MuiSnackbar-anchorOriginBottomLeft {
        left: 24px;
        right: auto;
        bottom: 24px;
    }
}

.MuiToolbar-root {
    display: flex;
    position: relative;
    align-items: center;
}

.MuiToolbar-gutters {
    padding-left: 16px;
    padding-right: 16px;
}

@media (min-width:600px) {
    .MuiToolbar-gutters {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.MuiToolbar-regular {
    min-height: 56px;
}

@media (min-width:0px) and (orientation: landscape) {
    .MuiToolbar-regular {
        min-height: 48px;
    }
}

@media (min-width:600px) {
    .MuiToolbar-regular {
        min-height: 64px;
    }
}

.MuiToolbar-dense {
    min-height: 48px;
}

.MuiTooltip-popper {
    z-index: 1500;
    pointer-events: none;
}

.MuiTooltip-popperInteractive {
    pointer-events: auto;
}

.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
    top: 0;
    left: 0;
    margin-top: -0.71em;
    margin-left: 4px;
    margin-right: 4px;
}

.MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow {
    left: 0;
    bottom: 0;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: -0.71em;
}

.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow {
    left: 0;
    width: 0.71em;
    height: 1em;
    margin-top: 4px;
    margin-left: -0.71em;
    margin-bottom: 4px;
}

.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow {
    right: 0;
    width: 0.71em;
    height: 1em;
    margin-top: 4px;
    margin-right: -0.71em;
    margin-bottom: 4px;
}

.MuiTooltip-popperArrow[x-placement*="left"] .MuiTooltip-arrow::before {
    transform-origin: 0 0;
}

.MuiTooltip-popperArrow[x-placement*="right"] .MuiTooltip-arrow::before {
    transform-origin: 100% 100%;
}

.MuiTooltip-popperArrow[x-placement*="top"] .MuiTooltip-arrow::before {
    transform-origin: 100% 0;
}

.MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow::before {
    transform-origin: 0 100%;
}

.MuiTooltip-tooltip {
    color: #fff;
    padding: 6px;
    font-size: 0.8125rem;
    max-width: 300px;
    word-wrap: break-word;
    font-family: "Work Sans", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.4em;
    padding-left: 10px;
    border-radius: 4px;
    padding-right: 10px;
    background-color: rgb(18,18,20);
}

.MuiTooltip-tooltipArrow {
    margin: 0;
    position: relative;
}

.MuiTooltip-arrow {
    color: rgba(97, 97, 97, 0.9);
    width: 1em;
    height: 0.71em;
    overflow: hidden;
    position: absolute;
    box-sizing: border-box;
}

.MuiTooltip-arrow::before {
    width: 100%;
    height: 100%;
    margin: auto;
    content: "";
    display: block;
    transform: rotate(45deg);
    background-color: currentColor;
}

.MuiTooltip-touch {
    padding: 8px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.14286em;
}

.MuiTooltip-tooltipPlacementLeft {
    margin: 0 24px;
    transform-origin: right center;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementLeft {
        margin: 0 14px;
    }
}

.MuiTooltip-tooltipPlacementRight {
    margin: 0 24px;
    transform-origin: left center;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementRight {
        margin: 0 14px;
    }
}

.MuiTooltip-tooltipPlacementTop {
    margin: 24px 0;
    transform-origin: center bottom;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementTop {
        margin: 14px 0;
    }
}

.MuiTooltip-tooltipPlacementBottom {
    margin: 24px 0;
    transform-origin: center top;
}

@media (min-width:600px) {
    .MuiTooltip-tooltipPlacementBottom {
        margin: 14px 0;
    }
}

.MuiSkeleton-root {
    height: 1.2em;
    display: block;
    background-color: rgba(28, 28, 28, 0.13);
}

.MuiSkeleton-text {
    height: auto;
    transform: scale(1, 0.60);
    margin-top: 0;
    border-radius: 6px;
    margin-bottom: 0;
    transform-origin: 0 60%;
}

.MuiSkeleton-text:empty:before {
    content: "\00a0";
}

.MuiSkeleton-circle {
    border-radius: 50%;
}

.MuiSkeleton-pulse {
    animation: MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
}

@keyframes MuiSkeleton-keyframes-pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}

.MuiSkeleton-wave {
    overflow: hidden;
    position: relative;
}

.MuiSkeleton-wave::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    animation: MuiSkeleton-keyframes-wave 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.08), transparent);
}

@keyframes MuiSkeleton-keyframes-wave {
    0% {
        transform: translateX(-100%);
    }

    60% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.MuiSkeleton-withChildren>* {
    visibility: hidden;
}

.MuiSkeleton-fitContent {
    max-width: fit-content;
}

.MuiSkeleton-heightAuto {
    height: auto;
}

.jss20 {
    color: rgba(187, 187, 187, 1);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2.6px;
    text-transform: uppercase;
}

.jss12 {
    font-weight: 400;
}

@media (max-width:959.95px) {
    .jss12 {
        display: none;
    }
}

.jss13 {
    top: 56px;
}

@media (max-width:959.95px) {
    .jss13 {
        top: 0;
    }
}

.jss14 {
    color: #fff;
    background-color: rgb(18,18,20);
}

.jss15 {
    color: #fff;
    margin: 16px 0px;
    display: block;
    font-size: 16px;
    text-decoration: none;
}

.jss16 {
    top: 8px;
    right: 8px;
    position: fixed;
}

.jss17 {
    margin-left: 8px;
}

@media (max-width:959.95px) {
    .jss17 {
        display: none;
    }
}

.jss18 {
    margin-right: 8px;
}

@media (max-width:959.95px) {
    .jss18 {
        margin-right: 0;
    }
}

.jss25 {
    font-weight: 400;
}

@media (max-width:959.95px) {
    .jss25 {
        display: none;
    }
}

.jss26 {
    top: 56px;
}

@media (max-width:959.95px) {
    .jss26 {
        top: 0;
    }
}

.jss27 {
    color: #fff;
    background-color: rgb(18,18,20);
}

.jss28 {
    color: #fff;
    margin: 16px 0px;
    display: block;
    font-size: 16px;
    text-decoration: none;
}

.jss29 {
    top: 8px;
    right: 8px;
    position: fixed;
}

.jss30 {
    margin-left: 8px;
}

@media (max-width:959.95px) {
    .jss30 {
        display: none;
    }
}

.jss31 {
    margin-right: 8px;
}

@media (max-width:959.95px) {
    .jss31 {
        margin-right: 0;
    }
}

.jss23 {
    height: 40px;
    display: flex;
    padding: 6px 22px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 0;
    text-transform: none;
}

.jss24:after {
    width: 65%;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    border-bottom: 2px solid rgba(240, 171, 21, 1);
}

.jss22 {
    display: flex;
    align-items: center;
}

@media (max-width:959.95px) {
    .jss22 {
        display: none;
    }
}

.jss21 {
    cursor: pointer;
    height: 100%;
    min-width: 140px;
    min-height: 45px;
}

.jss9 {
    width: auto;
    padding: 16px 24px;
    background-color: rgb(18,18,20);
}

.jss10 {
    color: #fff;
    font-size: 1.35rem;
    font-weight: 300;
}

.jss11 {
    color: #757575;
}

.jss3 {
    padding: 0 24px;
}

@media (min-width:960px) {
    .jss4 {
        display: none;
    }
}

.jss5 {
    padding: 6px 25px;
    font-size: 16px;
    text-transform: none;
}

.jss6 {
    display: flex;
    align-items: center;
}

.jss7 {
    color: #fff;
    min-width: 150px;
    background-color: rgb(18,18,20);
}

.jss7 a {
    color: #fff;
}

.jss8 {
    z-index: 1201;
    box-shadow: none;
    background-color: transparent;
}

.jss427 {
    color: #fff;
    background-color: rgba(34, 34, 34, 1);
}

.jss428 {
    color: #fff;
    margin: 12px 0px;
    display: block;
    font-size: 16px;
    text-decoration: none;
}

.jss429 {
    margin-top: 16px;
    margin-right: 16px;
}

/* .jss1 {
    min-height: 100vh;
    transition: background-color 1s ease;
    background-color: rgb(18,18,20);
} */

.jss436 .MuiSnackbarContent-root {
    background-color: #212121;
}

.jss437 {
    color: #e0e0e0;
}

.jss51 {
    color: rgba(255, 255, 255, 1);
    overflow: hidden;
    flex-grow: 1;
}

.jss52 {
    padding: 30px;
    flex-direction: column;
}

@media (max-width:1279.95px) {
    .jss52 {
        padding: 16px;
    }
}

.jss53 {
    background-color: rgb(18,18,20);
}

.jss54 {
    background-color: rgb(18,18,20);
}

.jss41 {
    display: flex;
    z-index: 1;
    /* position: relative; */
    margin-top: -84px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-color: rgb(18,18,20);
    height: 100%;
}

@media (max-width:959.95px) {
    .jss41 {
        padding: 0px 24px;
        text-align: left;
        align-items: start;
        background-position: 900px 0px;
    }
}

.jss42 {
    margin: 32px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media (max-width:959.95px) {
    .jss42 {
        align-items: start;
    }
}

.jss43 {
    color: rgba(255, 255, 255, 1);
    font-weight: 300;
}

@media (max-width:1279.95px) {
    .jss43.jss43 {
        font-size: 3.5rem;
    }
}

.jss44 {
    color: rgba(255, 255, 255, 1);
    width: 50%;
}

@media (max-width:1279.95px) {
    .jss44 {
        width: 100%;
    }
}

.jss45 {
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 9px;
    text-transform: uppercase;
}

.jss45:not(:last-of-type):after {
    width: 4px;
    height: 4px;
    margin: 0px 32px;
    content: "";
    display: inline-block;
    border-radius: 2px;
    background-color: #fff;
}

@media (max-width:1279.95px) {
    .jss45 {
        flex-direction: column;
    }
}

@media (max-width:1279.95px) {
    .jss45:not(:last-of-type):after {
        display: none;
    }
}

.jss46 {
    margin: 28px 0px;
}

@media (max-width:1279.95px) {
    .jss46 {
        margin: 0;
        align-items: start;
    }
}

.jss47 {
    background-image: url("../../../public/images/img_frame69.png");
    
}

@media (max-width:959.95px) {
    .jss47 {
        background-image: linear-gradient(to bottom, rgb(18,18,20) 5%, rgb(38 38 38 / 26%) 65%, rgba(58, 58, 58, 1) 80%, rgb(18,18,20) 100%), url("../../../public/images/img_frame69.png");
    }
}

.jss48 {
    font-size: 8.5rem;
}

.jss55 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    align-items: baseline;
    margin-bottom: 24px;
}

.jss56 {
    color: rgba(187, 187, 187, 1);
}

.jss57 {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    margin-right: 12px;
}

.jss58 {
    color: transparent;
    animation: 6s ease 0s infinite alternate none running jss60;
    background-clip: text;
    background-size: 200% 100%;
    background-image: linear-gradient(112deg, rgb(18,18,20) 0%, #E80D71 51.1%, rgb(18,18,20) 120.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.jss59 {
    color: rgba(255, 255, 255, 1);
    flex-basis: 100%;
    margin-top: 8px;
}

.jss59>* {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 8px;
}

@keyframes jss60 {
    0% {
        background-position: 0px 0px;
    }

    100% {
        background-position: 100% 0px;
    }
}

.jss109 {
    display: flex;
    z-index: 1;
    flex-wrap: wrap;
}

.jss109>*:not(:last-of-type) {
    margin-right: 16px;
}

.jss109>* {
    margin-top: 8px;
    margin-bottom: 8px;
}

.jss110 {
    color: #fff;
}

.jss110>svg {
    color: white;
}

.jss111 {
    animation: jss112 2s infinite;
}

@keyframes jss112 {
    0% {
        box-shadow: 0 0 0 0 rgb(73, 185, 86, 0.8);
    }

    70% {
        box-shadow: 0 0 0 8px rgba(73, 185, 86, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(73, 185, 86, 0);
    }
}

.jss113 {
    background-color: rgba(73, 185, 86, 1);
    padding: 13.6px 6.4px;
}

.jss113>.MuiChip-labelSmall {}

.jss114 {
    background-color: transparent;
     
}

.jss114>.MuiChip-labelSmall {}

.jss121 {
    background-color: rgba(73, 185, 86, 1);
    padding: 13.6px 6.4px;
}

.jss121>.MuiChip-labelSmall {}

.jss122 {
    background-color: transparent;
    
}

.jss122>.MuiChip-labelSmall {}

.jss135 {
    background-color: rgba(73, 185, 86, 1);
    padding: 13.6px 6.4px;
}

.jss135>.MuiChip-labelSmall {}

.jss136 {
    background-color: transparent;
     
}

.jss136>.MuiChip-labelSmall {}

.jss140 {
    background-color: rgba(73, 185, 86, 1);
    padding: 13.6px 6.4px;
}

.jss140>.MuiChip-labelSmall {}

.jss141 {
    background-color: transparent;
     
}

.jss141>.MuiChip-labelSmall {}

.jss223 {
    background-color: rgba(73, 185, 86, 1);
    padding: 13.6px 6.4px;
}

.jss223>.MuiChip-labelSmall {}

.jss224 {
    background-color: transparent;
     
}

.jss224>.MuiChip-labelSmall {}

.jss288 {
    background-color: rgba(73, 185, 86, 1);
    padding: 13.6px 6.4px;
}

.jss288>.MuiChip-labelSmall {}

.jss289 {
    background-color: transparent;
     
}

.jss289>.MuiChip-labelSmall {}

.jss95 {
    width: 125px;
}

.jss96 {
    color: rgba(240, 171, 21, 1);
    cursor: pointer;
}

.jss96:hover {
    text-decoration: underline;
}

.jss97 {
    display: flex;
}

.jss99 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss102 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss105 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss108 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss117 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss120 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss125 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss128 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss131 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss134 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss139 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss144 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss147 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss150 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss153 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss156 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss159 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss162 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss165 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss168 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss171 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss174 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss177 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss180 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss183 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss186 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss189 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss192 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss195 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss198 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss201 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss204 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss207 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss210 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss213 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss216 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss219 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss222 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss227 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss230 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss233 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss236 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss239 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss242 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss245 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss248 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss251 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss254 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss257 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss260 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss263 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss266 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss269 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss272 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss275 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss278 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss281 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss284 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss287 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss292 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss295 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss298 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss301 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss304 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss307 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss310 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss313 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss316 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss319 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss322 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss325 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss328 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss331 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss334 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss337 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss340 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss343 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss346 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss349 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss352 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss355 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss358 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss361 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss364 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss367 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss370 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: none;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss373 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss376 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss379 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss382 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss385 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss388 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss391 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss394 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss397 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss400 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss403 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss406 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss409 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 8px;
    margin: 0px 0px 0px 8px;
    display: block;
    min-width: 8px;
    border-radius: 4px;
    background-color: rgba(73, 185, 86, 1);
}

.jss78 {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.jss79 {
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 6px 7.2px 12px 12px;
    z-index: 1;
    position: relative;
    border-radius: 6px;
    flex-direction: column;
}

.jss79:hover .jss84 {
    max-height: 300px;
}

.jss81 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    object-fit: cover;
    border-radius: 6px;
    object-position: center;
}

.jss82 {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 500;
}

.jss83 {
    margin: -20px;
    display: flex;
    padding: 20px;
    z-index: 1;
    background: linear-gradient(to top, rgb(0 0 0 / 90%) 0%, rgb(0 0 0 / 0%) 100%, rgb(0 0 0 / 0%) 100%);
    border-radius: 6px;
    flex-direction: column;
}

.jss84 {
    overflow: hidden;
    max-height: 125px;
    transition: max-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
}

.jss85 {
    color: rgba(255, 255, 255, 1);
    padding-top: 4px;
    padding-bottom: 4px;
}

.jss85 .name {
    font-size: 1.2rem;
    margin-top: 4px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 2.4px;
}

.jss85 .subheading {
    font-size: 1rem;
    line-height: 1.3;
}

@media (max-width:959.95px) {
    .jss85 {
        min-height: auto;
    }

    .jss85 .name {
        font-size: 1rem;
    }

    .jss85 .subheading {
        font-size: 0.875rem;
    }
}

.jss86 {
    z-index: 1;
}

.jss88 {
    color: #e0e0e0;
    display: -webkit-box;
    overflow: hidden;
    box-orient: vertical;
    font-style: italic;
    line-clamp: 2;
    margin-top: 3.2px;
    text-overflow: ellipsis;
}

@media (max-width:959.95px) {
    .jss88 {
        color: #9e9e9e;
        font-size: 0.815rem;
        line-height: 1.4;
    }
}

.jss89 {
    right: 10px;
    bottom: 10px;
    z-index: 1;
    position: absolute;
}

@media (max-width:959.95px) {
    .jss89 {
        right: 6px;
        bottom: 6px;
    }
}

.jss90 {
    padding: 4px;
    border-radius: 6px;
    background-color: rgba(28, 28, 28, 0.75);
}

.jss91 {
    color: #e0e0e0;
    display: inline;
    font-size: 0.875rem;
}

.jss92 {
    font-size: 0.875rem;
    font-weight: 600;
}

.jss93 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss93 {
        height: 200px;
        padding: 4px;
    }
}

.jss94 {
    z-index: 1;
}

.jss94 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss94 {}

    .jss94.jss94 .chip-container {
        margin-left: 4px;
    }

    .jss94.jss94 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss94.jss94 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss94.jss94 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss94.jss94 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss94 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss94 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss94 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss94 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss100 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss100 {
        height: 200px;
        padding: 4px;
    }
}

.jss101 {
    z-index: 1;
}

.jss101 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss101 {}

    .jss101.jss101 .chip-container {
        margin-left: 4px;
    }

    .jss101.jss101 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss101.jss101 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss101.jss101 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss101.jss101 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss101 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss101 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss101 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss101 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss103 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss103 {
        height: 200px;
        padding: 4px;
    }
}

.jss104 {
    z-index: 1;
}

.jss104 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss104 {}

    .jss104.jss104 .chip-container {
        margin-left: 4px;
    }

    .jss104.jss104 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss104.jss104 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss104.jss104 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss104.jss104 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss104 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss104 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss104 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss104 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss106 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss106 {
        height: 200px;
        padding: 4px;
    }
}

.jss107 {
    z-index: 1;
}

.jss107 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss107 {}

    .jss107.jss107 .chip-container {
        margin-left: 4px;
    }

    .jss107.jss107 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss107.jss107 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss107.jss107 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss107.jss107 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss107 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss107 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss107 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss107 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss115 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss115 {
        height: 200px;
        padding: 4px;
    }
}

.jss116 {
    z-index: 1;
}

.jss116 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss116 {}

    .jss116.jss116 .chip-container {
        margin-left: 4px;
    }

    .jss116.jss116 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss116.jss116 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss116.jss116 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss116.jss116 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss116 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss116 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss116 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss116 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss118 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss118 {
        height: 200px;
        padding: 4px;
    }
}

.jss119 {
    z-index: 1;
}

.jss119 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss119 {}

    .jss119.jss119 .chip-container {
        margin-left: 4px;
    }

    .jss119.jss119 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss119.jss119 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss119.jss119 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss119.jss119 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss119 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss119 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss119 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss119 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss123 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss123 {
        height: 200px;
        padding: 4px;
    }
}

.jss124 {
    z-index: 1;
}

.jss124 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss124 {}

    .jss124.jss124 .chip-container {
        margin-left: 4px;
    }

    .jss124.jss124 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss124.jss124 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss124.jss124 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss124.jss124 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss124 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss124 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss124 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss124 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss126 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss126 {
        height: 200px;
        padding: 4px;
    }
}

.jss127 {
    z-index: 1;
}

.jss127 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss127 {}

    .jss127.jss127 .chip-container {
        margin-left: 4px;
    }

    .jss127.jss127 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss127.jss127 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss127.jss127 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss127.jss127 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss127 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss127 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss127 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss127 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss129 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss129 {
        height: 200px;
        padding: 4px;
    }
}

.jss130 {
    z-index: 1;
}

.jss130 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss130 {}

    .jss130.jss130 .chip-container {
        margin-left: 4px;
    }

    .jss130.jss130 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss130.jss130 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss130.jss130 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss130.jss130 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss130 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss130 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss130 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss130 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss132 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss132 {
        height: 200px;
        padding: 4px;
    }
}

.jss133 {
    z-index: 1;
}

.jss133 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss133 {}

    .jss133.jss133 .chip-container {
        margin-left: 4px;
    }

    .jss133.jss133 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss133.jss133 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss133.jss133 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss133.jss133 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss133 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss133 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss133 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss133 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss137 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss137 {
        height: 200px;
        padding: 4px;
    }
}

.jss138 {
    z-index: 1;
}

.jss138 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss138 {}

    .jss138.jss138 .chip-container {
        margin-left: 4px;
    }

    .jss138.jss138 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss138.jss138 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss138.jss138 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss138.jss138 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss138 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss138 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss138 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss138 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss142 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss142 {
        height: 200px;
        padding: 4px;
    }
}

.jss143 {
    z-index: 1;
}

.jss143 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss143 {}

    .jss143.jss143 .chip-container {
        margin-left: 4px;
    }

    .jss143.jss143 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss143.jss143 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss143.jss143 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss143.jss143 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss143 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss143 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss143 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss143 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss145 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss145 {
        height: 200px;
        padding: 4px;
    }
}

.jss146 {
    z-index: 1;
}

.jss146 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss146 {}

    .jss146.jss146 .chip-container {
        margin-left: 4px;
    }

    .jss146.jss146 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss146.jss146 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss146.jss146 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss146.jss146 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss146 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss146 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss146 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss146 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss148 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss148 {
        height: 200px;
        padding: 4px;
    }
}

.jss149 {
    z-index: 1;
}

.jss149 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss149 {}

    .jss149.jss149 .chip-container {
        margin-left: 4px;
    }

    .jss149.jss149 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss149.jss149 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss149.jss149 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss149.jss149 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss149 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss149 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss149 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss149 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss151 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss151 {
        height: 200px;
        padding: 4px;
    }
}

.jss152 {
    z-index: 1;
}

.jss152 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss152 {}

    .jss152.jss152 .chip-container {
        margin-left: 4px;
    }

    .jss152.jss152 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss152.jss152 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss152.jss152 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss152.jss152 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss152 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss152 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss152 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss152 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss154 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss154 {
        height: 200px;
        padding: 4px;
    }
}

.jss155 {
    z-index: 1;
}

.jss155 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss155 {}

    .jss155.jss155 .chip-container {
        margin-left: 4px;
    }

    .jss155.jss155 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss155.jss155 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss155.jss155 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss155.jss155 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss155 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss155 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss155 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss155 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss157 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss157 {
        height: 200px;
        padding: 4px;
    }
}

.jss158 {
    z-index: 1;
}

.jss158 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss158 {}

    .jss158.jss158 .chip-container {
        margin-left: 4px;
    }

    .jss158.jss158 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss158.jss158 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss158.jss158 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss158.jss158 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss158 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss158 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss158 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss158 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss160 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss160 {
        height: 200px;
        padding: 4px;
    }
}

.jss161 {
    z-index: 1;
}

.jss161 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss161 {}

    .jss161.jss161 .chip-container {
        margin-left: 4px;
    }

    .jss161.jss161 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss161.jss161 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss161.jss161 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss161.jss161 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss161 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss161 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss161 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss161 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss163 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss163 {
        height: 200px;
        padding: 4px;
    }
}

.jss164 {
    z-index: 1;
}

.jss164 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss164 {}

    .jss164.jss164 .chip-container {
        margin-left: 4px;
    }

    .jss164.jss164 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss164.jss164 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss164.jss164 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss164.jss164 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss164 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss164 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss164 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss164 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss166 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss166 {
        height: 200px;
        padding: 4px;
    }
}

.jss167 {
    z-index: 1;
}

.jss167 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss167 {}

    .jss167.jss167 .chip-container {
        margin-left: 4px;
    }

    .jss167.jss167 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss167.jss167 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss167.jss167 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss167.jss167 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss167 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss167 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss167 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss167 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss169 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss169 {
        height: 200px;
        padding: 4px;
    }
}

.jss170 {
    z-index: 1;
}

.jss170 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss170 {}

    .jss170.jss170 .chip-container {
        margin-left: 4px;
    }

    .jss170.jss170 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss170.jss170 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss170.jss170 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss170.jss170 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss170 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss170 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss170 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss170 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss172 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss172 {
        height: 200px;
        padding: 4px;
    }
}

.jss173 {
    z-index: 1;
}

.jss173 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss173 {}

    .jss173.jss173 .chip-container {
        margin-left: 4px;
    }

    .jss173.jss173 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss173.jss173 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss173.jss173 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss173.jss173 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss173 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss173 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss173 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss173 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss175 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss175 {
        height: 200px;
        padding: 4px;
    }
}

.jss176 {
    z-index: 1;
}

.jss176 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss176 {}

    .jss176.jss176 .chip-container {
        margin-left: 4px;
    }

    .jss176.jss176 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss176.jss176 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss176.jss176 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss176.jss176 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss176 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss176 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss176 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss176 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss178 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss178 {
        height: 200px;
        padding: 4px;
    }
}

.jss179 {
    z-index: 1;
}

.jss179 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss179 {}

    .jss179.jss179 .chip-container {
        margin-left: 4px;
    }

    .jss179.jss179 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss179.jss179 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss179.jss179 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss179.jss179 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss179 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss179 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss179 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss179 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss181 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss181 {
        height: 200px;
        padding: 4px;
    }
}

.jss182 {
    z-index: 1;
}

.jss182 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss182 {}

    .jss182.jss182 .chip-container {
        margin-left: 4px;
    }

    .jss182.jss182 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss182.jss182 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss182.jss182 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss182.jss182 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss182 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss182 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss182 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss182 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss184 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss184 {
        height: 200px;
        padding: 4px;
    }
}

.jss185 {
    z-index: 1;
}

.jss185 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss185 {}

    .jss185.jss185 .chip-container {
        margin-left: 4px;
    }

    .jss185.jss185 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss185.jss185 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss185.jss185 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss185.jss185 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss185 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss185 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss185 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss185 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss187 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss187 {
        height: 200px;
        padding: 4px;
    }
}

.jss188 {
    z-index: 1;
}

.jss188 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss188 {}

    .jss188.jss188 .chip-container {
        margin-left: 4px;
    }

    .jss188.jss188 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss188.jss188 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss188.jss188 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss188.jss188 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss188 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss188 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss188 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss188 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss190 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss190 {
        height: 200px;
        padding: 4px;
    }
}

.jss191 {
    z-index: 1;
}

.jss191 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss191 {}

    .jss191.jss191 .chip-container {
        margin-left: 4px;
    }

    .jss191.jss191 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss191.jss191 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss191.jss191 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss191.jss191 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss191 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss191 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss191 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss191 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss193 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss193 {
        height: 200px;
        padding: 4px;
    }
}

.jss194 {
    z-index: 1;
}

.jss194 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss194 {}

    .jss194.jss194 .chip-container {
        margin-left: 4px;
    }

    .jss194.jss194 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss194.jss194 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss194.jss194 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss194.jss194 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss194 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss194 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss194 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss194 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss196 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss196 {
        height: 200px;
        padding: 4px;
    }
}

.jss197 {
    z-index: 1;
}

.jss197 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss197 {}

    .jss197.jss197 .chip-container {
        margin-left: 4px;
    }

    .jss197.jss197 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss197.jss197 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss197.jss197 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss197.jss197 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss197 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss197 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss197 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss197 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss199 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss199 {
        height: 200px;
        padding: 4px;
    }
}

.jss200 {
    z-index: 1;
}

.jss200 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss200 {}

    .jss200.jss200 .chip-container {
        margin-left: 4px;
    }

    .jss200.jss200 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss200.jss200 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss200.jss200 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss200.jss200 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss200 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss200 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss200 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss200 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss202 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss202 {
        height: 200px;
        padding: 4px;
    }
}

.jss203 {
    z-index: 1;
}

.jss203 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss203 {}

    .jss203.jss203 .chip-container {
        margin-left: 4px;
    }

    .jss203.jss203 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss203.jss203 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss203.jss203 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss203.jss203 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss203 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss203 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss203 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss203 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss205 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss205 {
        height: 200px;
        padding: 4px;
    }
}

.jss206 {
    z-index: 1;
}

.jss206 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss206 {}

    .jss206.jss206 .chip-container {
        margin-left: 4px;
    }

    .jss206.jss206 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss206.jss206 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss206.jss206 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss206.jss206 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss206 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss206 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss206 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss206 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss208 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss208 {
        height: 200px;
        padding: 4px;
    }
}

.jss209 {
    z-index: 1;
}

.jss209 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss209 {}

    .jss209.jss209 .chip-container {
        margin-left: 4px;
    }

    .jss209.jss209 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss209.jss209 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss209.jss209 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss209.jss209 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss209 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss209 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss209 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss209 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss211 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss211 {
        height: 200px;
        padding: 4px;
    }
}

.jss212 {
    z-index: 1;
}

.jss212 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss212 {}

    .jss212.jss212 .chip-container {
        margin-left: 4px;
    }

    .jss212.jss212 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss212.jss212 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss212.jss212 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss212.jss212 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss212 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss212 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss212 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss212 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss214 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss214 {
        height: 200px;
        padding: 4px;
    }
}

.jss215 {
    z-index: 1;
}

.jss215 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss215 {}

    .jss215.jss215 .chip-container {
        margin-left: 4px;
    }

    .jss215.jss215 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss215.jss215 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss215.jss215 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss215.jss215 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss215 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss215 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss215 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss215 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss217 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss217 {
        height: 200px;
        padding: 4px;
    }
}

.jss218 {
    z-index: 1;
}

.jss218 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss218 {}

    .jss218.jss218 .chip-container {
        margin-left: 4px;
    }

    .jss218.jss218 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss218.jss218 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss218.jss218 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss218.jss218 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss218 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss218 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss218 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss218 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss220 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss220 {
        height: 200px;
        padding: 4px;
    }
}

.jss221 {
    z-index: 1;
}

.jss221 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss221 {}

    .jss221.jss221 .chip-container {
        margin-left: 4px;
    }

    .jss221.jss221 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss221.jss221 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss221.jss221 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss221.jss221 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss221 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss221 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss221 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss221 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss225 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss225 {
        height: 200px;
        padding: 4px;
    }
}

.jss226 {
    z-index: 1;
}

.jss226 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss226 {}

    .jss226.jss226 .chip-container {
        margin-left: 4px;
    }

    .jss226.jss226 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss226.jss226 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss226.jss226 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss226.jss226 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss226 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss226 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss226 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss226 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss228 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss228 {
        height: 200px;
        padding: 4px;
    }
}

.jss229 {
    z-index: 1;
}

.jss229 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss229 {}

    .jss229.jss229 .chip-container {
        margin-left: 4px;
    }

    .jss229.jss229 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss229.jss229 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss229.jss229 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss229.jss229 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss229 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss229 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss229 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss229 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss231 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss231 {
        height: 200px;
        padding: 4px;
    }
}

.jss232 {
    z-index: 1;
}

.jss232 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss232 {}

    .jss232.jss232 .chip-container {
        margin-left: 4px;
    }

    .jss232.jss232 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss232.jss232 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss232.jss232 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss232.jss232 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss232 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss232 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss232 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss232 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss234 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss234 {
        height: 200px;
        padding: 4px;
    }
}

.jss235 {
    z-index: 1;
}

.jss235 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss235 {}

    .jss235.jss235 .chip-container {
        margin-left: 4px;
    }

    .jss235.jss235 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss235.jss235 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss235.jss235 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss235.jss235 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss235 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss235 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss235 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss235 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss237 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss237 {
        height: 200px;
        padding: 4px;
    }
}

.jss238 {
    z-index: 1;
}

.jss238 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss238 {}

    .jss238.jss238 .chip-container {
        margin-left: 4px;
    }

    .jss238.jss238 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss238.jss238 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss238.jss238 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss238.jss238 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss238 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss238 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss238 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss238 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss240 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss240 {
        height: 200px;
        padding: 4px;
    }
}

.jss241 {
    z-index: 1;
}

.jss241 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss241 {}

    .jss241.jss241 .chip-container {
        margin-left: 4px;
    }

    .jss241.jss241 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss241.jss241 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss241.jss241 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss241.jss241 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss241 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss241 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss241 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss241 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss243 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss243 {
        height: 200px;
        padding: 4px;
    }
}

.jss244 {
    z-index: 1;
}

.jss244 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss244 {}

    .jss244.jss244 .chip-container {
        margin-left: 4px;
    }

    .jss244.jss244 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss244.jss244 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss244.jss244 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss244.jss244 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss244 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss244 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss244 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss244 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss246 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss246 {
        height: 200px;
        padding: 4px;
    }
}

.jss247 {
    z-index: 1;
}

.jss247 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss247 {}

    .jss247.jss247 .chip-container {
        margin-left: 4px;
    }

    .jss247.jss247 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss247.jss247 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss247.jss247 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss247.jss247 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss247 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss247 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss247 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss247 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss249 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss249 {
        height: 200px;
        padding: 4px;
    }
}

.jss250 {
    z-index: 1;
}

.jss250 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss250 {}

    .jss250.jss250 .chip-container {
        margin-left: 4px;
    }

    .jss250.jss250 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss250.jss250 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss250.jss250 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss250.jss250 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss250 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss250 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss250 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss250 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss252 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss252 {
        height: 200px;
        padding: 4px;
    }
}

.jss253 {
    z-index: 1;
}

.jss253 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss253 {}

    .jss253.jss253 .chip-container {
        margin-left: 4px;
    }

    .jss253.jss253 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss253.jss253 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss253.jss253 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss253.jss253 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss253 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss253 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss253 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss253 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss255 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss255 {
        height: 200px;
        padding: 4px;
    }
}

.jss256 {
    z-index: 1;
}

.jss256 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss256 {}

    .jss256.jss256 .chip-container {
        margin-left: 4px;
    }

    .jss256.jss256 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss256.jss256 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss256.jss256 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss256.jss256 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss256 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss256 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss256 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss256 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss258 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss258 {
        height: 200px;
        padding: 4px;
    }
}

.jss259 {
    z-index: 1;
}

.jss259 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss259 {}

    .jss259.jss259 .chip-container {
        margin-left: 4px;
    }

    .jss259.jss259 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss259.jss259 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss259.jss259 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss259.jss259 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss259 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss259 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss259 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss259 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss261 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss261 {
        height: 200px;
        padding: 4px;
    }
}

.jss262 {
    z-index: 1;
}

.jss262 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss262 {}

    .jss262.jss262 .chip-container {
        margin-left: 4px;
    }

    .jss262.jss262 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss262.jss262 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss262.jss262 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss262.jss262 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss262 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss262 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss262 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss262 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss264 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss264 {
        height: 200px;
        padding: 4px;
    }
}

.jss265 {
    z-index: 1;
}

.jss265 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss265 {}

    .jss265.jss265 .chip-container {
        margin-left: 4px;
    }

    .jss265.jss265 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss265.jss265 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss265.jss265 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss265.jss265 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss265 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss265 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss265 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss265 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss267 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss267 {
        height: 200px;
        padding: 4px;
    }
}

.jss268 {
    z-index: 1;
}

.jss268 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss268 {}

    .jss268.jss268 .chip-container {
        margin-left: 4px;
    }

    .jss268.jss268 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss268.jss268 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss268.jss268 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss268.jss268 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss268 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss268 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss268 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss268 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss270 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss270 {
        height: 200px;
        padding: 4px;
    }
}

.jss271 {
    z-index: 1;
}

.jss271 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss271 {}

    .jss271.jss271 .chip-container {
        margin-left: 4px;
    }

    .jss271.jss271 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss271.jss271 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss271.jss271 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss271.jss271 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss271 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss271 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss271 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss271 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss273 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss273 {
        height: 200px;
        padding: 4px;
    }
}

.jss274 {
    z-index: 1;
}

.jss274 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss274 {}

    .jss274.jss274 .chip-container {
        margin-left: 4px;
    }

    .jss274.jss274 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss274.jss274 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss274.jss274 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss274.jss274 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss274 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss274 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss274 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss274 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss276 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss276 {
        height: 200px;
        padding: 4px;
    }
}

.jss277 {
    z-index: 1;
}

.jss277 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss277 {}

    .jss277.jss277 .chip-container {
        margin-left: 4px;
    }

    .jss277.jss277 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss277.jss277 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss277.jss277 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss277.jss277 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss277 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss277 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss277 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss277 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss279 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss279 {
        height: 200px;
        padding: 4px;
    }
}

.jss280 {
    z-index: 1;
}

.jss280 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss280 {}

    .jss280.jss280 .chip-container {
        margin-left: 4px;
    }

    .jss280.jss280 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss280.jss280 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss280.jss280 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss280.jss280 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss280 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss280 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss280 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss280 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss282 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss282 {
        height: 200px;
        padding: 4px;
    }
}

.jss283 {
    z-index: 1;
}

.jss283 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss283 {}

    .jss283.jss283 .chip-container {
        margin-left: 4px;
    }

    .jss283.jss283 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss283.jss283 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss283.jss283 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss283.jss283 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss283 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss283 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss283 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss283 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss285 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss285 {
        height: 200px;
        padding: 4px;
    }
}

.jss286 {
    z-index: 1;
}

.jss286 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss286 {}

    .jss286.jss286 .chip-container {
        margin-left: 4px;
    }

    .jss286.jss286 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss286.jss286 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss286.jss286 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss286.jss286 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss286 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss286 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss286 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss286 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss290 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss290 {
        height: 200px;
        padding: 4px;
    }
}

.jss291 {
    z-index: 1;
}

.jss291 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss291 {}

    .jss291.jss291 .chip-container {
        margin-left: 4px;
    }

    .jss291.jss291 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss291.jss291 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss291.jss291 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss291.jss291 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss291 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss291 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss291 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss291 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss293 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss293 {
        height: 200px;
        padding: 4px;
    }
}

.jss294 {
    z-index: 1;
}

.jss294 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss294 {}

    .jss294.jss294 .chip-container {
        margin-left: 4px;
    }

    .jss294.jss294 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss294.jss294 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss294.jss294 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss294.jss294 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss294 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss294 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss294 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss294 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss296 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss296 {
        height: 200px;
        padding: 4px;
    }
}

.jss297 {
    z-index: 1;
}

.jss297 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss297 {}

    .jss297.jss297 .chip-container {
        margin-left: 4px;
    }

    .jss297.jss297 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss297.jss297 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss297.jss297 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss297.jss297 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss297 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss297 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss297 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss297 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss299 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss299 {
        height: 200px;
        padding: 4px;
    }
}

.jss300 {
    z-index: 1;
}

.jss300 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss300 {}

    .jss300.jss300 .chip-container {
        margin-left: 4px;
    }

    .jss300.jss300 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss300.jss300 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss300.jss300 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss300.jss300 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss300 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss300 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss300 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss300 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss302 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss302 {
        height: 200px;
        padding: 4px;
    }
}

.jss303 {
    z-index: 1;
}

.jss303 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss303 {}

    .jss303.jss303 .chip-container {
        margin-left: 4px;
    }

    .jss303.jss303 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss303.jss303 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss303.jss303 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss303.jss303 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss303 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss303 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss303 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss303 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss305 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss305 {
        height: 200px;
        padding: 4px;
    }
}

.jss306 {
    z-index: 1;
}

.jss306 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss306 {}

    .jss306.jss306 .chip-container {
        margin-left: 4px;
    }

    .jss306.jss306 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss306.jss306 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss306.jss306 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss306.jss306 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss306 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss306 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss306 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss306 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss308 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss308 {
        height: 200px;
        padding: 4px;
    }
}

.jss309 {
    z-index: 1;
}

.jss309 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss309 {}

    .jss309.jss309 .chip-container {
        margin-left: 4px;
    }

    .jss309.jss309 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss309.jss309 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss309.jss309 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss309.jss309 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss309 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss309 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss309 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss309 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss311 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss311 {
        height: 200px;
        padding: 4px;
    }
}

.jss312 {
    z-index: 1;
}

.jss312 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss312 {}

    .jss312.jss312 .chip-container {
        margin-left: 4px;
    }

    .jss312.jss312 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss312.jss312 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss312.jss312 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss312.jss312 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss312 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss312 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss312 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss312 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss314 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss314 {
        height: 200px;
        padding: 4px;
    }
}

.jss315 {
    z-index: 1;
}

.jss315 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss315 {}

    .jss315.jss315 .chip-container {
        margin-left: 4px;
    }

    .jss315.jss315 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss315.jss315 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss315.jss315 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss315.jss315 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss315 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss315 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss315 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss315 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss317 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss317 {
        height: 200px;
        padding: 4px;
    }
}

.jss318 {
    z-index: 1;
}

.jss318 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss318 {}

    .jss318.jss318 .chip-container {
        margin-left: 4px;
    }

    .jss318.jss318 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss318.jss318 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss318.jss318 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss318.jss318 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss318 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss318 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss318 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss318 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss320 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss320 {
        height: 200px;
        padding: 4px;
    }
}

.jss321 {
    z-index: 1;
}

.jss321 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss321 {}

    .jss321.jss321 .chip-container {
        margin-left: 4px;
    }

    .jss321.jss321 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss321.jss321 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss321.jss321 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss321.jss321 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss321 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss321 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss321 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss321 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss323 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss323 {
        height: 200px;
        padding: 4px;
    }
}

.jss324 {
    z-index: 1;
}

.jss324 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss324 {}

    .jss324.jss324 .chip-container {
        margin-left: 4px;
    }

    .jss324.jss324 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss324.jss324 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss324.jss324 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss324.jss324 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss324 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss324 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss324 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss324 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss326 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss326 {
        height: 200px;
        padding: 4px;
    }
}

.jss327 {
    z-index: 1;
}

.jss327 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss327 {}

    .jss327.jss327 .chip-container {
        margin-left: 4px;
    }

    .jss327.jss327 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss327.jss327 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss327.jss327 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss327.jss327 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss327 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss327 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss327 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss327 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss329 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss329 {
        height: 200px;
        padding: 4px;
    }
}

.jss330 {
    z-index: 1;
}

.jss330 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss330 {}

    .jss330.jss330 .chip-container {
        margin-left: 4px;
    }

    .jss330.jss330 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss330.jss330 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss330.jss330 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss330.jss330 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss330 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss330 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss330 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss330 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss332 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss332 {
        height: 200px;
        padding: 4px;
    }
}

.jss333 {
    z-index: 1;
}

.jss333 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss333 {}

    .jss333.jss333 .chip-container {
        margin-left: 4px;
    }

    .jss333.jss333 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss333.jss333 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss333.jss333 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss333.jss333 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss333 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss333 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss333 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss333 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss335 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss335 {
        height: 200px;
        padding: 4px;
    }
}

.jss336 {
    z-index: 1;
}

.jss336 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss336 {}

    .jss336.jss336 .chip-container {
        margin-left: 4px;
    }

    .jss336.jss336 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss336.jss336 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss336.jss336 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss336.jss336 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss336 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss336 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss336 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss336 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss338 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss338 {
        height: 200px;
        padding: 4px;
    }
}

.jss339 {
    z-index: 1;
}

.jss339 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss339 {}

    .jss339.jss339 .chip-container {
        margin-left: 4px;
    }

    .jss339.jss339 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss339.jss339 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss339.jss339 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss339.jss339 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss339 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss339 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss339 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss339 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss341 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss341 {
        height: 200px;
        padding: 4px;
    }
}

.jss342 {
    z-index: 1;
}

.jss342 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss342 {}

    .jss342.jss342 .chip-container {
        margin-left: 4px;
    }

    .jss342.jss342 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss342.jss342 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss342.jss342 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss342.jss342 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss342 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss342 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss342 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss342 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss344 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss344 {
        height: 200px;
        padding: 4px;
    }
}

.jss345 {
    z-index: 1;
}

.jss345 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss345 {}

    .jss345.jss345 .chip-container {
        margin-left: 4px;
    }

    .jss345.jss345 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss345.jss345 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss345.jss345 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss345.jss345 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss345 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss345 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss345 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss345 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss347 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss347 {
        height: 200px;
        padding: 4px;
    }
}

.jss348 {
    z-index: 1;
}

.jss348 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss348 {}

    .jss348.jss348 .chip-container {
        margin-left: 4px;
    }

    .jss348.jss348 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss348.jss348 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss348.jss348 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss348.jss348 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss348 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss348 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss348 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss348 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss350 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss350 {
        height: 200px;
        padding: 4px;
    }
}

.jss351 {
    z-index: 1;
}

.jss351 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss351 {}

    .jss351.jss351 .chip-container {
        margin-left: 4px;
    }

    .jss351.jss351 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss351.jss351 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss351.jss351 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss351.jss351 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss351 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss351 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss351 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss351 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss353 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss353 {
        height: 200px;
        padding: 4px;
    }
}

.jss354 {
    z-index: 1;
}

.jss354 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss354 {}

    .jss354.jss354 .chip-container {
        margin-left: 4px;
    }

    .jss354.jss354 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss354.jss354 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss354.jss354 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss354.jss354 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss354 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss354 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss354 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss354 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss356 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss356 {
        height: 200px;
        padding: 4px;
    }
}

.jss357 {
    z-index: 1;
}

.jss357 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss357 {}

    .jss357.jss357 .chip-container {
        margin-left: 4px;
    }

    .jss357.jss357 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss357.jss357 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss357.jss357 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss357.jss357 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss357 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss357 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss357 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss357 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss359 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss359 {
        height: 200px;
        padding: 4px;
    }
}

.jss360 {
    z-index: 1;
}

.jss360 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss360 {}

    .jss360.jss360 .chip-container {
        margin-left: 4px;
    }

    .jss360.jss360 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss360.jss360 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss360.jss360 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss360.jss360 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss360 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss360 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss360 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss360 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss362 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss362 {
        height: 200px;
        padding: 4px;
    }
}

.jss363 {
    z-index: 1;
}

.jss363 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss363 {}

    .jss363.jss363 .chip-container {
        margin-left: 4px;
    }

    .jss363.jss363 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss363.jss363 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss363.jss363 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss363.jss363 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss363 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss363 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss363 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss363 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss365 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss365 {
        height: 200px;
        padding: 4px;
    }
}

.jss366 {
    z-index: 1;
}

.jss366 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss366 {}

    .jss366.jss366 .chip-container {
        margin-left: 4px;
    }

    .jss366.jss366 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss366.jss366 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss366.jss366 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss366.jss366 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss366 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss366 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss366 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss366 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss368 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss368 {
        height: 200px;
        padding: 4px;
    }
}

.jss369 {
    z-index: 1;
}

.jss369 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss369 {}

    .jss369.jss369 .chip-container {
        margin-left: 4px;
    }

    .jss369.jss369 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss369.jss369 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss369.jss369 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss369.jss369 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss369 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss369 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss369 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss369 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss371 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss371 {
        height: 200px;
        padding: 4px;
    }
}

.jss372 {
    z-index: 1;
}

.jss372 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss372 {}

    .jss372.jss372 .chip-container {
        margin-left: 4px;
    }

    .jss372.jss372 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss372.jss372 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss372.jss372 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss372.jss372 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss372 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss372 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss372 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss372 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss374 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss374 {
        height: 200px;
        padding: 4px;
    }
}

.jss375 {
    z-index: 1;
}

.jss375 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss375 {}

    .jss375.jss375 .chip-container {
        margin-left: 4px;
    }

    .jss375.jss375 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss375.jss375 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss375.jss375 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss375.jss375 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss375 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss375 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss375 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss375 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss377 {
    height: 450px;
}

@media (max-width:959.95px) {
    .jss377 {
        height: 200px;
        padding: 4px;
    }
}

.jss378 {
    z-index: 1;
}

.jss378 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss378 {}

    .jss378.jss378 .chip-container {
        margin-left: 4px;
    }

    .jss378.jss378 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss378.jss378 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss378.jss378 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss378.jss378 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss378 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss378 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss378 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss378 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss380 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss380 {
        height: 200px;
        padding: 4px;
    }
}

.jss381 {
    z-index: 1;
}

.jss381 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss381 {}

    .jss381.jss381 .chip-container {
        margin-left: 4px;
    }

    .jss381.jss381 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss381.jss381 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss381.jss381 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss381.jss381 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss381 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss381 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss381 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss381 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss383 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss383 {
        height: 200px;
        padding: 4px;
    }
}

.jss384 {
    z-index: 1;
}

.jss384 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss384 {}

    .jss384.jss384 .chip-container {
        margin-left: 4px;
    }

    .jss384.jss384 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss384.jss384 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss384.jss384 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss384.jss384 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss384 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss384 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss384 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss384 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss386 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss386 {
        height: 200px;
        padding: 4px;
    }
}

.jss387 {
    z-index: 1;
}

.jss387 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss387 {}

    .jss387.jss387 .chip-container {
        margin-left: 4px;
    }

    .jss387.jss387 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss387.jss387 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss387.jss387 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss387.jss387 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss387 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss387 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss387 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss387 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss389 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss389 {
        height: 200px;
        padding: 4px;
    }
}

.jss390 {
    z-index: 1;
}

.jss390 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss390 {}

    .jss390.jss390 .chip-container {
        margin-left: 4px;
    }

    .jss390.jss390 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss390.jss390 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss390.jss390 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss390.jss390 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss390 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss390 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss390 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss390 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss392 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss392 {
        height: 200px;
        padding: 4px;
    }
}

.jss393 {
    z-index: 1;
}

.jss393 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss393 {}

    .jss393.jss393 .chip-container {
        margin-left: 4px;
    }

    .jss393.jss393 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss393.jss393 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss393.jss393 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss393.jss393 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss393 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss393 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss393 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss393 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss395 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss395 {
        height: 200px;
        padding: 4px;
    }
}

.jss396 {
    z-index: 1;
}

.jss396 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss396 {}

    .jss396.jss396 .chip-container {
        margin-left: 4px;
    }

    .jss396.jss396 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss396.jss396 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss396.jss396 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss396.jss396 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss396 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss396 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss396 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss396 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss398 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss398 {
        height: 200px;
        padding: 4px;
    }
}

.jss399 {
    z-index: 1;
}

.jss399 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss399 {}

    .jss399.jss399 .chip-container {
        margin-left: 4px;
    }

    .jss399.jss399 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss399.jss399 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss399.jss399 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss399.jss399 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss399 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss399 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss399 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss399 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss401 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss401 {
        height: 200px;
        padding: 4px;
    }
}

.jss402 {
    z-index: 1;
}

.jss402 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss402 {}

    .jss402.jss402 .chip-container {
        margin-left: 4px;
    }

    .jss402.jss402 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss402.jss402 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss402.jss402 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss402.jss402 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss402 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss402 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss402 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss402 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss404 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss404 {
        height: 200px;
        padding: 4px;
    }
}

.jss405 {
    z-index: 1;
}

.jss405 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss405 {}

    .jss405.jss405 .chip-container {
        margin-left: 4px;
    }

    .jss405.jss405 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss405.jss405 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss405.jss405 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss405.jss405 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss405 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss405 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss405 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss405 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss407 {
    height: 320px;
}

@media (max-width:959.95px) {
    .jss407 {
        height: 200px;
        padding: 4px;
    }
}

.jss408 {
    z-index: 1;
}

.jss408 .chip-container {
    display: flex;
}

@media (max-width:959.95px) {
    .jss408 {}

    .jss408.jss408 .chip-container {
        margin-left: 4px;
    }

    .jss408.jss408 .chip-container>* {
        font-size: 0.7rem;
    }

    .jss408.jss408 .chip-container svg {
        width: 10px;
        height: 14px;
    }

    .jss408.jss408 .chip-container .MuiChip-sizeSmall {
        height: 20px;
        padding: 0;
    }

    .jss408.jss408 .chip-container .MuiChip-label {
        padding: 0px 4px;
    }
}

.jss408 .chip-container>* {
    font-size: 0.8rem;
    margin-top: 4px;
    margin-right: 4px;
}

.jss408 .chip-container svg {
    width: 15px;
    height: 19px;
}

.jss408 .chip-container .MuiChip-sizeSmall {
    height: 20px;
    padding: 13.6px 5.6px;
}

.jss408 .chip-container .MuiChip-label {
    padding: 0px 4px;
}

.jss410 {
    color: rgba(255, 255, 255, 1);
    height: 100%;
    display: flex;
    border-color: rgba(58, 58, 58, 1);
    flex-direction: column;
    background-color: rgb(18,18,20);
}

.jss411 {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.jss412 {
    height: 200px;
}

.jss413 {
    padding: 8px;
}

.jss75 {
    width: 100%;
    height: 100%;
    min-width: 200px;
    background-color: #424242;
}

.jss70 {
    width: 100%;
    cursor: pointer;
    height: 100%;
    display: flex;
    padding: 8px;
    z-index: 1;
    position: relative;
    text-align: center;
    align-items: center;
    border-radius: 6px;
    flex-direction: column;
    justify-content: center;
}

.jss71 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    position: absolute;
    object-fit: cover;
    border-radius: 6px;
    object-position: center;
   
    
}

.jss72 {
    color: rgba(255, 255, 255, 1);
    z-index: 1;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 3px;
    pointer-events: none;
    text-transform: uppercase;
}

@media (max-width:1279.95px) {
    .jss72 {
        font-size: 14px;
    }
}

.jss73 {
    color: rgba(255, 255, 255, 1);
    z-index: 1;
}

.jss74 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 6px;
    background-color: rgb(0 0 0 / 60%);
}

.jss74:hover {
    background-color: rgb(0 0 0 / 30%);
}

.jss63 {
    position: relative;
}

.jss64 {
    margin-top: 16px;
    text-align: center;
}

.jss65 {
    width: 52px;
    height: 3px;
    margin: 7px 5px;
    display: inline-block;
    border-radius: 2px;
    background-color: rgb(18,18,20);
    transition-duration: 300ms;
}

.jss66 {
    top: 24.5%;
    width: 40px;
    cursor: pointer;
    height: 40px;
    display: flex;
    z-index: 10;
    position: absolute;
    transform: translateY(-50%);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    background-color: white;
}

.jss67 {
    right: -17px;
}

.jss68 {
    left: -17px;
}

.jss69 {
    opacity: 0.3;
}

.jss76 {
    opacity: 0.3;
}

.jss77 {
    opacity: 0.3;
}

.jss425 h2 {
    color: rgba(255, 255, 255, 1);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.235;
}

.jss425 h3 {
    color: rgba(255, 255, 255, 1);
    font-size: 1.3rem;
    font-weight: 500;
}

.jss425 p {
    color: rgba(255, 255, 255, 1);
    font-size: 1rem;
    font-weight: 300;
}

.jss32 {
    margin-bottom: 64px;
}

.jss33 {
    color: rgba(255, 255, 255, 1);
    width: 50%;
    font-weight: 300;
}

@media (max-width:1279.95px) {
    .jss33 {
        width: 100%;
    }
}

.jss34 {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    line-height: 1;
    margin-bottom: 16px;
}

@media (max-width:1279.95px) {
    .jss34 {
        font-size: 3.5rem;
    }
}

@media (max-width:959.95px) {
    .jss35 {
        height: auto;
    }
}

@media (max-width:959.95px) {
    .jss36 {
        height: auto;
    }
}

.jss37 {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

@media (max-width:1919.95px) {
    .jss37 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width:1279.95px) {
    .jss37 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width:959.95px) {
    .jss37 {
        gap: 16px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.jss38 {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media (max-width:1919.95px) {
    .jss38 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media (max-width:1279.95px) {
    .jss38 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width:959.95px) {
    .jss38 {
        gap: 16px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.jss39 {
    gap: 16px;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
}

@media (max-width:1919.95px) {
    .jss39 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
}

@media (max-width:1279.95px) {
    .jss39 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (max-width:959.95px) {
    .jss39 {
        gap: 16px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.jss40 button:first-of-type {
    margin-right: 16px;
}

@media (max-width:599.95px) {
    .jss40 button:first-of-type {
        margin-bottom: 16px;
    }
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNis.woff) format('woff')
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNis.woff) format('woff')
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNis.woff) format('woff')
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNis.woff) format('woff')
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNis.woff) format('woff')
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_cqDptfpA4cD3.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}



@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_fKDptfpA4Q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}


@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/worksans/v19/QGYsz_wNahGAdqQ43Rh_c6DptfpA4cD3.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}

.htmlData p{
    font-size: 1.2em;
    color: #FFFFFF;
    line-height: 1.3;
}



.htmlData strong{
    color: #E80D71;
    font-size: 1.2em;
}
.htmlData a {
 
  
    color: #E80D71;
}

.htmlData h1 span{
   
    text-align: left;

    text-transform: capitalize;
    Font-size: 30px !important;
    Font-weight: 700 !important;
    Line-height: 32px !important;
    color: rgb(255, 255, 255) !important;
}

.htmlData h1 {
    text-align: left;
    margin-bottom: 20px;
     text-transform: capitalize;
     Font-size: 30px !important;
     Font-weight: 700 !important;
     Line-height: 32px !important;
     color: rgb(255, 255, 255) !important;
}
.htmlData h1 strong {
    text-align: left;
    margin-bottom: 20px;
     text-transform: capitalize;
     Font-size: 30px !important;
     Font-weight: 700 !important;
     Line-height: 32px !important;
     color: rgb(255, 255, 255) !important;
}




