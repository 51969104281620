

.for_model{
    position: absolute !important;
    width: 100%;
}
.modal-animation {
  transition: transform 0.5s ease-in-out; /* You can adjust the duration and easing as needed */
  transform: translateY(-100%);
}

.modal-animation.show {
  transform: translateY(0);
}

.navbar h4{
  color: white!important;
  font-size: 1.3em!important;
  font-weight: bold;
}
  